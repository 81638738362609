import { useEffect, useState } from "react";

const HeaderSticky = (offset = 0) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    setSticky(window.scrollY > offset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return sticky;
};

export default HeaderSticky;
