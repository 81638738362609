import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import MobileNav from './MobileNav';
import HeaderSticky from './HeaderSticky';
import ResponsiveMenu from './ResponsiveMenu';

// eslint-disable-next-line no-unused-vars
const MobileHeader = ({ styles, disableSticky, categories, trips, showSearchIcon = true, showAccountIcon = false }) => {
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);

    const onCanvasHandler = () => {
        setOffcanvasShow((prevState) => !prevState);
    };

    const onSearchHandler = () => {
        setSearchPopup((prevState) => !prevState);
    };

    const sticky = HeaderSticky(56);
    const classes = sticky ? 'sticky' : '';
    const stickyStatus = disableSticky ? '' : ' header-sticky';
    const router = useRouter();
    return (
        <>
            <header
                className={`edu-header disable-transparent landing-demo-header ${stickyStatus} ${styles || ''} ${classes || ''
                    } kw-mobile-header`}
            >
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-2 col-xl-2 col-md-4 col-4'>
                            <div className='logo'>
                                <Link href={'/'}>
                                    <a>
                                        <img
                                            className='logo-light'
                                            src='/kaarwan/icons/kaarwan-logo-gradient.svg'
                                            alt='Main Logo'
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>

                        <div className='col-lg-10  d-none d-xl-block'>
                            <nav className='mainmenu-nav d-none d-lg-block'>
                                <MobileNav categories={categories} trips={trips} />
                            </nav>
                        </div>

                        <div className='col-lg-8 col-xl-3 col-md-6 col-8'>
                            <div className='header-right d-flex justify-content-end'>
                                {/*  Hide in student dashboard deployment */}
                                {showAccountIcon ? (
                                    <div className='mobile-menu-bar mr--15 mr_sm--5 d-block d-xl-none'>
                                        <div className='hamberger'>
                                            <Link
                                                href={`/account/login/?name=${router.query.name}&tripId=${router.query.id}`}
                                            >
                                                <a>
                                                    <button
                                                        className='white-box-icon hamberger-button header-menu'
                                                        type='button'
                                                        name='open-menu'
                                                        aria-label='menu'
                                                    >
                                                        <i className='ri-user-line text-dark'></i>
                                                    </button>
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                ) : null}

                                {/* Show in student dashboard deployment */}
                                {/* <div className='mobile-menu-bar mr--15 mr_sm--5 d-block d-xl-none'>
                                    <div className='hamberger'>
                                        <Link href={authUrl}>
                                            <a>
                                                <button
                                                    className='white-box-icon hamberger-button header-menu text-dark'
                                                    type='button'
                                                    name='hamburger'
                                                    aria-label='menu'
                                                >
                                                    <i className='ri-user-line'></i>
                                                </button>
                                            </a>
                                        </Link>
                                    </div>
                                </div> */}

                                {/*  Hide in student dashboard deployment */}

                                {/* {showSearchIcon ? (
                  <div className='header-menu-bar'>
                    <div className='quote-icon quote-search'>
                      <button
                        type='button'
                        name='box-icon'
                        arial-label='mobile-search'
                        className='white-box-icon search-trigger header-search'
                        onClick={onSearchHandler}
                      >
                        <i className='ri-search-line'></i>
                      </button>
                    </div>
                  </div>
                ) : null} */}

                                {/* Show in Student dashbooard deployment */}

                                <div className='header-menu-bar'>
                                    <div className='quote-icon quote-search'>
                                        <button
                                            type='button'
                                            name='search'
                                            arial-label='mobile-search'
                                            className='white-box-icon search-trigger header-search d-flex align-items-center justify-content-center'
                                            onClick={onSearchHandler}
                                        >
                                            <SearchIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className='mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none'>
                                    <div className='hamberger'>
                                        <button
                                            className='white-box-icon hamberger-button header-menu d-flex align-items-center justify-content-center'
                                            onClick={onCanvasHandler}
                                            type='button'
                                            name='open-menu'
                                            aria-label='menu'
                                        >
                                            <MenuIcon />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ResponsiveMenu
                show={offcanvasShow}
                onClose={onCanvasHandler}
                showSearch={searchPopup}
                onSearch={onSearchHandler}
                categories={categories}
                trips={trips}
            />
        </>
    );
};

export default MobileHeader;

export const SearchIcon = (props) => (
    <svg width={19} height={19} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M16.625 16.625L11.875 11.875M13.4583 7.91667C13.4583 8.64441 13.315 9.36503 13.0365 10.0374C12.758 10.7097 12.3498 11.3206 11.8352 11.8352C11.3206 12.3498 10.7097 12.758 10.0374 13.0365C9.36503 13.315 8.64441 13.4583 7.91667 13.4583C7.18892 13.4583 6.46831 13.315 5.79596 13.0365C5.12362 12.758 4.51271 12.3498 3.99812 11.8352C3.48353 11.3206 3.07533 10.7097 2.79683 10.0374C2.51834 9.36503 2.375 8.64441 2.375 7.91667C2.375 6.44693 2.95885 5.03738 3.99812 3.99812C5.03738 2.95885 6.44693 2.375 7.91667 2.375C9.38641 2.375 10.796 2.95885 11.8352 3.99812C12.8745 5.03738 13.4583 6.44693 13.4583 7.91667Z'
            stroke='#444D56'
            strokeWidth={1.58333}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const MenuIcon = (props) => (
    <svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M3 4.75H17M3 10H17M3 15.25H9.125'
            stroke='#444D56'
            strokeWidth={1.67}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
