import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { getDatesBetween, getShortTitle, getWorkshopURL, removeDuplicates, slugify } from '../../../utils';
import { useRouter } from 'next/router';
import moment from 'moment';

export const MasterclassesIcons = {
    33: 'bim.webp',
    32: 'rhino.webp',
    34: 'photoshop.webp',
    55: 'vray.webp',
    14: 'vastu.webp',
    9: 'site-analysis.webp',
    27: 'arch-comp.webp',
    29: 'facade.webp',
    65: 'arc-thesis-guide.webp',
    17: 'interior-styling.webp',
    49: 'interior-design.webp',
    21: 'ui-ux.webp',
    46: 'crack-gate.webp',
}

const DesktopHeaderVer2 = ({ classes = '', categories = [], trips, setBgBlur, isDark = false }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const _data = []
        if (categories && categories.length) {
            Array.from(categories).forEach((cat) => {
                const _masterclasses = [];
                const _courses = []
                if (cat?.Subcategory && cat?.Subcategory.length) {
                    Array.from(cat?.Subcategory).forEach((subCat) => {
                        if (subCat?.Workshops && subCat?.Workshops.length) {
                            Array.from(subCat?.Workshops).forEach((ws) => {
                                if (ws?.type === 1) {
                                    _masterclasses.push(ws)
                                } else {
                                    _courses.push(ws)
                                }
                            })
                        }
                    })
                }
                _data.push({
                    id: cat?.id,
                    name: cat?.name,
                    masterclasses: _masterclasses,
                    courses: _courses
                })
            })
        }
        setData(_data);
    }, [categories]);

    const router = useRouter()

    let _params = '';
    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .filter((item) => item[0] !== 'name')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });


    const [isActive, setIsActive] = useState(true);


    useEffect(() => {
        if (!isActive) {
            setTimeout(() => {
                setIsActive(true);
                setBgBlur(false);
            }, 100);
        }
    }, [isActive, setBgBlur])

    return (
        <div className={`kwn-desktop-header-ver2 ${classes} ${isDark ? 'header-dark' : ''}`} id='kwn-desktop-header-ver2' >
            <div className={`container`} >
                <div className="sub-container d-flex align-items-center justify-content-between">
                    <div className="logo-wrapper">
                        <Link href="/">
                            <a>
                                <img src="/kaarwan/icons/kaarwan-logo-gradient.svg" alt="" />
                            </a>
                        </Link>
                    </div>
                    <div className="center-tabs d-flex align-items-center">
                        {data.length ? Array.from(data).map((cat, ind) => (
                            <div className={`header-tab header-tab-${ind}`} key={cat?.id} onMouseEnter={() => setBgBlur(true)} onMouseLeave={() => setBgBlur(false)}>
                                <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0'>
                                    <span className='text'>{cat?.name}</span>
                                    <Arrow />
                                </button>
                                {isActive ? <div className={`data-preview-area tab-section-${ind + 1}`} >
                                    <div className="inner-section">
                                        {data && data[ind]?.masterclasses && data[ind]?.masterclasses.length ? <div className="masterclasses-list">
                                            <span className="heading text-uppercase d-block">
                                                · 1-day Masterclass
                                            </span>

                                            <ul className="d-flex align-items-center flex-wrap list-unstyled m-0">
                                                {data && data.length ? Array.from(removeDuplicates(data[ind].masterclasses, 'id')).map((workshop) => (
                                                    <li key={workshop?.id}>
                                                        <Link href={getWorkshopURL(workshop?.type, workshop?.name, workshop?.id, workshop?.url, _params)}>
                                                            <a className='rounded-pill' onClick={() => setIsActive(false)}>
                                                                <span className="icon">
                                                                    <img src={`/constant/header-icons/masterclasses/${MasterclassesIcons[`${workshop?.id}`]}`} alt="bim" width={24} height={24} />
                                                                </span>
                                                                <span className="title">{getShortTitle(
                                                                    workshop?.shortTitleType,
                                                                    workshop?.shortTitle,
                                                                    workshop?.name,
                                                                    1
                                                                )}</span>
                                                            </a>
                                                        </Link>
                                                    </li>
                                                )) : null}
                                            </ul>
                                        </div> : null}

                                        {data && data[ind] && data[ind].courses && data[ind].courses.length ? <div className={`courses-list ${data && data[ind]?.masterclasses && data[ind]?.masterclasses.length ? 'mt-3' : ''}`}>
                                            <span className="heading text-uppercase">
                                                · Certification Courses
                                            </span>
                                            <div className="row gy-4">
                                                {data && data.length ? Array.from(removeDuplicates(data[ind].courses, 'id')).map((workshop) => (
                                                    <div className={`ltc-course-card ${TABS_COL_CLASSES[ind]}`} key={workshop?.id}>
                                                        <Link href={getWorkshopURL(workshop?.type, workshop?.name, workshop?.id, workshop?.url, _params)}>
                                                            <a className='d-flex align-items-start' onClick={() => setIsActive(false)}>
                                                                <div className="image-wrapper overflow-hidden">
                                                                    <img src={`https://kaarwan.s3.amazonaws.com/${workshop?.image}`} alt='thumb' />
                                                                </div>
                                                                <div className="data-wrapper">
                                                                    <span className="title two-line-title">{getShortTitle(
                                                                        workshop?.shortTitleType,
                                                                        workshop?.shortTitle,
                                                                        workshop?.name,
                                                                        1
                                                                    )}</span>

                                                                    <div className="meta-info d-flex mt-2">
                                                                        <div className="weeks text-nowrap">
                                                                            <ClockIcon1 />
                                                                            <span className="text">
                                                                                {workshop?.days}
                                                                            </span>
                                                                        </div>
                                                                        <div className="weeks ms-3 text-nowrap">
                                                                            <ClockIcon2 />
                                                                            <span className="text">
                                                                                {workshop?.hours}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </Link>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div> : null}
                                    </div>
                                </div> : null}
                            </div>
                        )) : <>
                            <div className="header-tab">
                                <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0' >
                                    <span className='text'>Architecture</span>
                                    <Arrow />
                                </button>
                            </div>
                            <div className="header-tab">
                                <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0' >
                                    <span className='text'>Interior Design</span>
                                    <Arrow />
                                </button>
                            </div>
                            <div className="header-tab">
                                <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0' >
                                    <span className='text'>UI-UX Design</span>
                                    <Arrow />
                                </button>
                            </div>
                            <div className="header-tab">
                                <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0' >
                                    <span className='text'>GATE 2025</span>
                                    <Arrow />
                                </button>
                            </div>
                        </>}
                        <div className="header-tab">
                            <Link href="/courses-and-workshops">
                                <a>
                                    <button type='button' className='btn-course d-flex align-items-center justify-content-center border-0 m-0' >
                                        <span className='text'>All Courses</span>
                                    </button>
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="trips-tab">
                        <button type='button' className='btn-trips d-flex align-items-center justify-content-center border-0 m-0' onMouseEnter={() => setBgBlur(true)} onMouseLeave={() => setBgBlur(false)}>
                            <span className='text'>Academic Trips</span>
                            <Arrow />
                        </button>
                        {isActive ? <div className="trips-card" onMouseEnter={() => setBgBlur(true)} onMouseLeave={() => setBgBlur(false)}>
                            <div className={`inner-wrapper trips-count-${trips && trips.length ? trips.length : 'trips-count-0'}`}>
                                <div className="d-flex align-items-center">
                                    {trips && trips.length ? <div className="upcoming-trips h-100">
                                        <span className="heading">
                                            UPCOMING TRIPS
                                        </span>
                                        <div className="d-flex align-items-center trips-list-wrapper">
                                            {Array.from(trips).map((trip) => (
                                                <Link href={`/education-travel/${slugify(trip.name || trip.url)}?id=${trip.id}`} key={trip.id}>
                                                    <a onClick={() => setIsActive(false)}>
                                                        <div className="upcoming-trip-card-wrapper overflow-hidden h-100">
                                                            <div className="image-wrapper overflow-hidden" style={{ maxHeight: '133px' }}>
                                                                <img src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${trip.thumbnail || trip.image}`} alt="kaarwan-trip" />
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <span className="trip-name one-line-title">
                                                                    {trip?.name}
                                                                </span>

                                                                <div className="meta-info d-flex align-items-center">
                                                                    <div className='d-flex align-items-center'>
                                                                        <CalendarIcon />
                                                                        <span className="text ms-2">
                                                                            {moment(new Date(trip.startDate)).format("MMM Do YYYY")}
                                                                        </span>
                                                                    </div>
                                                                    <div className='d-flex align-items-center ms-3'>
                                                                        <DaysIcon />
                                                                        <span className="text ms-2">
                                                                            {getDatesBetween(trip.startDate, trip.endDate, true).length} Days
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></a>
                                                </Link>
                                            ))}
                                        </div>

                                    </div> : null}

                                    <div className={`recent-trips ${trips && trips.length ? 'ms-3' : ''}`}>
                                        <span className="heading text-nowrap">
                                            OUR RECENT TRIPS
                                        </span>
                                        <Link href="/travels/home">
                                            <a onClick={() => setIsActive(false)}>
                                                <img src="/constant/travel/header-image.png" alt="" style={{ maxHeight: '214px', objectFit: 'fill', minWidth: '220px' }} />
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopHeaderVer2;

const TABS_COL_CLASSES = {
    0: 'col-lg-3',
    1: 'col-lg-3',
    2: 'col-lg-12',
    3: 'col-lg-12'
}

const Arrow = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4 6.5L8 10.5L12 6.5"
            stroke="#2F2F2F"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const ClockIcon1 = (props) => (
    <svg
        width={18}
        height={19}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.9993 5.89844C8.19025 5.89844 8.37339 5.97429 8.50841 6.10932C8.64344 6.24435 8.7193 6.42748 8.7193 6.61844V9.20036L10.6683 11.1494C10.7995 11.2852 10.8721 11.4671 10.8704 11.6558C10.8688 11.8446 10.7931 12.0252 10.6596 12.1587C10.5261 12.2922 10.3455 12.3679 10.1567 12.3696C9.96792 12.3712 9.78605 12.2986 9.65026 12.1675L7.49026 10.0075C7.35522 9.87248 7.27934 9.68938 7.2793 9.49844V6.61844C7.2793 6.42748 7.35515 6.24435 7.49018 6.10932C7.62521 5.97429 7.80834 5.89844 7.9993 5.89844Z"
            fill="#2F2F2F"
            fillOpacity={0.7}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99883 3.73688C6.47118 3.73688 5.0061 4.34373 3.92589 5.42394C2.84568 6.50415 2.23883 7.96923 2.23883 9.49688C2.23883 11.0245 2.84568 12.4896 3.92589 13.5698C5.0061 14.65 6.47118 15.2569 7.99883 15.2569C9.52648 15.2569 10.9916 14.65 12.0718 13.5698C13.152 12.4896 13.7588 11.0245 13.7588 9.49688C13.7588 7.96923 13.152 6.50415 12.0718 5.42394C10.9916 4.34373 9.52648 3.73688 7.99883 3.73688ZM0.798828 9.49688C0.798828 5.52032 4.02227 2.29688 7.99883 2.29688C11.9754 2.29688 15.1988 5.52032 15.1988 9.49688C15.1988 13.4734 11.9754 16.6969 7.99883 16.6969C4.02227 16.6969 0.798828 13.4734 0.798828 9.49688Z"
            fill="#2F2F2F"
            fillOpacity={0.7}
        />
    </svg>
);

const ClockIcon2 = (props) => (
    <svg
        width={18}
        height={19}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3.49805 16.0625H12.4983"
            stroke="#2F2F2F"
            strokeOpacity={0.7}
            strokeWidth={1.26004}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.49805 3.20312H12.4983"
            stroke="#2F2F2F"
            strokeOpacity={0.7}
            strokeWidth={1.26004}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.212 16.0616V13.3795C11.2119 13.0385 11.0764 12.7115 10.8353 12.4705L7.9976 9.63281L5.15993 12.4705C4.91879 12.7115 4.78328 13.0385 4.7832 13.3795V16.0616"
            stroke="#2F2F2F"
            strokeOpacity={0.7}
            strokeWidth={1.26004}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.7832 3.20312V5.88521C4.78328 6.22619 4.91879 6.55317 5.15993 6.79424L7.9976 9.63191L10.8353 6.79424C11.0764 6.55317 11.2119 6.22619 11.212 5.88521V3.20312"
            stroke="#2F2F2F"
            strokeOpacity={0.7}
            strokeWidth={1.26004}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const CalendarIcon = (props) => (
    <svg
        width={14}
        height={15}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.0833 2.83594H2.91667C2.27233 2.83594 1.75 3.35827 1.75 4.0026V12.1693C1.75 12.8136 2.27233 13.3359 2.91667 13.3359H11.0833C11.7277 13.3359 12.25 12.8136 12.25 12.1693V4.0026C12.25 3.35827 11.7277 2.83594 11.0833 2.83594Z"
            stroke="#818181"
            strokeWidth={1.19792}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.33789 1.67188V4.00521"
            stroke="#818181"
            strokeWidth={1.19792}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.66211 1.67188V4.00521"
            stroke="#818181"
            strokeWidth={1.19792}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.75 6.33594H12.25"
            stroke="#818181"
            strokeWidth={1.19792}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);


const DaysIcon = (props) => (
    <svg
        width={14}
        height={15}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.0013 13.3307C10.223 13.3307 12.8346 10.7191 12.8346 7.4974C12.8346 4.27573 10.223 1.66406 7.0013 1.66406C3.77964 1.66406 1.16797 4.27573 1.16797 7.4974C1.16797 10.7191 3.77964 13.3307 7.0013 13.3307Z"
            stroke="#2F2F2F"
            strokeOpacity={0.5}
            strokeWidth={1.16667}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7 4V7.5L9.33333 8.66667"
            stroke="#2F2F2F"
            strokeOpacity={0.5}
            strokeWidth={1.16667}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);